<template>
    <b-container>
        <b-card title="Trading">
            <template v-if="!isLoading">
                <h5>{{ campaign.name }}</h5>
                <hr />
                <div v-for="campaignDetail in campaign.details" :key="campaignDetail.media_category" class="mb-3">
                    <template>
                        <h5 v-if="campaignDetail.label">{{ campaignDetail.label }}</h5>
                        <h5 v-else>{{ campaignDetail.media_category }}</h5>
                        <b-row>
                            <b-col cols="6">
                                <b-badge variant="primary">Volume initial : {{ campaignDetail.initialVolume |
                numberThousand }}</b-badge>
                                <br /><br />
                                <b-input v-model="campaignDetail.volume" @keypress="isNumber($event)"></b-input>
                            </b-col>
                            <b-col cols="6">
                                <b-badge variant="primary">Montant initial : {{ campaignDetail.initialAmount |
                numberThousand }}</b-badge>
                                <br /><br />
                                <b-input v-model="campaignDetail.amount" @keypress="isNumber($event)"></b-input>
                            </b-col>

                            <b-col class="mt-3">
                                <label for="range-1">Sélectionner un template</label>
                                <b-form-select v-model="campaignDetail.selectedTemplate"
                                    :options="getTemplateOptions(campaignDetail.media_category)"></b-form-select>
                            </b-col>
                        </b-row>
                        <b-row v-if="getTemplateOptions(campaignDetail.media_category).length > 0" class="mt-2">
                            <b-col cols="12">
                                <wysiwyg ref="wy" v-model="campaignDetail.selectedTemplate" :options="wysiwygOptions"
                                    @change="updateTextarea($event, campaignDetail)" />
                            </b-col>
                        </b-row>
                        <b-row v-else>
                            <b-col cols="12">
                                <p class="danger">No template found for this media_category</p>
                            </b-col>
                        </b-row>
                    </template>
                </div>

                <b-row class="justify-content-md-center">
                    <b-col cols="12">
                        <b-alert class="p-1" variant="primary" show>Montant total : {{ getTotal | numberThousand
                            }}</b-alert>
                    </b-col>

                    <b-btn variant="primary" class="mr-1" @click="redirectDashboard">Annuler</b-btn>
                    <b-btn variant="success" @click="openAlertModify">Modifier</b-btn>
                    <b-btn variant="success" class="ml-1" @click="openAlertValidate">Valider la campagne</b-btn>
                </b-row>
            </template>
        </b-card>
    </b-container>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
    data() {
        return {
            campaign: {},
            textarea: '',
            availableTemplates: [],
            hasInteractedWithSelect: false,
            default: 'default',
        };
    },
    computed: {
        ...mapGetters({
            availableMediasCode: 'app/availableMediasCode',
            successToast: 'app/successToast',
            errorToast: 'app/errorToast',
        }),

        wysiwygOptions() {
            return {
                hideModules: {
                    headings: true,
                    link: true,
                    code: true,
                    image: true,
                    table: true,
                },
                customModules: [this.createSizeModules(0.8, 'small'), this.createSizeModules(1, 'normal'), this.createSizeModules(1.2, 'big')],
            };
        },
        campaignCode() {
            return this.$route.params.campaign_code;
        },
        isLoading() {
            return Object.keys(this.campaign).length === 0;
        },
        getTotal() {
            return this.campaign.details.reduce((accumulator, camp) => {
                if (camp.amount) return accumulator + parseInt(camp.amount);
                return accumulator;
            }, 0);
        },
        getTemplateOptions() {
            return (mediaCategory) => {
                const templatesForMedia = this.availableTemplates.filter(template => template.value.media_category === mediaCategory);
                templatesForMedia.sort((a, b) => b.value.is_default - a.value.is_default);
                return templatesForMedia.map((template) => ({
                    value: template.value.comment,
                    text: template.text,
                }));
            };
        }

    },
    async created() {
        await this.getAllTemplates();
        this.init();
    },
    mounted() {
        window.ww = this;
    },
    methods: {
        createSizeModules(size, icon) {
            return {
                title: 'smallSize',
                customAction(utils) {
                    const sel = utils.getHTMLOfSelection();
                    return [['insertHTML', `<span style="font-size: ${size}em;">${sel}</span>`, true]];
                },
                icon: icon,
            };
        },
        updateSelectInteraction() {
            this.hasInteractedWithSelect = true;
        },
        async init() {
            this.$root.toggleGlobalLoading(true);

            if (this.availableMediasCode.length === 0) {
                await this.$store.dispatch('app/getAvailableMedias');
            }
            this.$store
                .dispatch('app/getSingleCampaign', this.campaignCode)
                .then((res) => {
                    let resCampaign = res.data.data[0];
                    let campaign = {
                        name: `${resCampaign.details.point_text} - ${resCampaign.details.label_quotation}`,
                        details: resCampaign.details.lines,
                        status: resCampaign.status,
                    };
                    if (resCampaign.details.finalData?.length > 0) {
                        campaign.finalData = resCampaign.details.finalData;
                    }

                    let storedMediaMetrics = this.$store.state.app.mediaMetrics;

                    for (let campaignMedia of campaign.details) {
                        let relatedMetric = null;
                        for (let key in resCampaign.metrics) {
                            let metricMedia = key.split('_').reverse()[0];
                            if (metricMedia === campaignMedia.media_category) relatedMetric = resCampaign.metrics[key];
                        }
                        campaignMedia.metric = relatedMetric;
                        let goal = null;
                        let configMedia = null;
                        if (relatedMetric) {
                            configMedia = storedMediaMetrics.find((media) => media.media === campaignMedia.media_category);
                            try {
                                let geolinkDetails = JSON.parse(configMedia.geolinkDetails);
                                goal = geolinkDetails.goal;
                            } catch (e) {
                                console.error(e);
                            }
                            campaignMedia.amount = !campaignMedia.quantity
                                ? campaignMedia.negotiated_price_excluding_taxes
                                : campaignMedia.metric.impressions > campaignMedia.quantity
                                    ? campaignMedia.negotiated_price_excluding_taxes
                                    : parseInt(((campaignMedia.negotiated_price_excluding_taxes / campaignMedia.quantity) * campaignMedia.metric.impressions).toFixed(), 10);
                            campaignMedia.volume = campaignMedia.metric[goal];
                        } else {
                            campaignMedia.amount = null;
                            campaignMedia.volume = null;
                        }

                        campaignMedia.label = configMedia?.label;
                        campaignMedia.goal = goal;


                        const defaultTemplate = this.getDefaultTemplate(campaignMedia.media_category);
                        if (defaultTemplate) {
                            campaignMedia.selectedTemplate = defaultTemplate;
                        }


                        campaignMedia.initialVolume = campaignMedia.quantity;
                        campaignMedia.initialAmount = campaignMedia.negotiated_price_excluding_taxes;

                        if (resCampaign.details.finalData?.length > 0) {

                            const finalDataMedia = campaign.finalData.find((dataMedia) => dataMedia.media == campaignMedia.media_category);

                            campaignMedia.volume = finalDataMedia?.volume;
                            campaignMedia.amount = finalDataMedia?.amount;
                            campaignMedia.selectedTemplate = finalDataMedia.comment ? finalDataMedia.comment : campaignMedia.selectedTemplate;

                        }
                    }

                    this.$set(this, 'campaign', campaign);
                })
                .catch((e) => {
                    console.error(e);
                })
                .finally(() => {
                    this.$root.toggleGlobalLoading(false);
                });
        },
        isNumber: function (evt) {
            evt = evt ? evt : window.event;
            var charCode = evt.which ? evt.which : evt.keyCode;

            if (charCode > 3 && (charCode < 48 || charCode > 57) && charCode !== 46) {
                evt.preventDefault();
            } else {
                return true;
            }
        },

        getDefaultTemplate(mediaCategory) {
            const templatesForMedia = this.availableTemplates.filter(template => template.value.media_category === mediaCategory);
            const defaultTemplate = templatesForMedia.find(template => template.value.is_default === 1);
            return defaultTemplate ? defaultTemplate.value.comment : null;
        },

        redirectDashboard() {
            this.$router.push('/');
        },

        openAlertModify() {
            this.openAlert('Êtes-vous sûr de vouloir modifier la campagne ?').then((confirmed) => {
                if (confirmed) this.updateCampaign();
            });
        },

        openAlertValidate() {
            this.openAlert('Êtes-vous sûr de vouloir valider la campagne ?').then(() => {
                this.updateCampaign(true);
            });
        },

        openAlert(msg) {
            return this.$bvModal.msgBoxConfirm(msg, {
                title: 'Please Confirm',
                size: 'sm',
                buttonSize: 'sm',
                okVariant: 'primary',
                okTitle: 'Oui',
                cancelTitle: 'Non',
                footerClass: 'p-2',
                hideHeaderClose: false,
                centered: true,
            });
        },

        async getAllTemplates() {
            try {
                const response = await this.$store.dispatch('app/getTradersTemplates');
                const templates = response.data;

                this.availableTemplates = templates.map((template) => ({
                    value: { comment: template.comment.text, media_category: template.media, is_default: template.is_default },
                    text: template.label,
                }));
            } catch (error) {
                console.error(error);
            }
        },

        updateCampaign(validate = false) {
            this.$root.toggleGlobalLoading(true);
            let data = {
                code: this.campaignCode,
                data: {
                    validate: validate,
                    status: validate ? this.campaign.status + 1 : this.campaign.status,
                    finalPrice: this.getTotal,
                    comment: this.textarea,
                    finalData: this.campaign.details.map((camp) => {
                        return {
                            media: camp.media_category,
                            volume: camp.volume,
                            amount: camp.amount,
                            comment: camp.selectedTemplate,
                        };
                    }),
                },
            };

            this.$store
                .dispatch('app/updateCampaignDetails', data)
                .then(async (res) => {
                    this.$toast(this.successToast('La campagne a été mise à jour avec succès. Vous allez être redirigé vers le tableau de campagnes'));
                    await this.$store.dispatch('app/modifiedCampaigns', true);
                    setTimeout(() => {
                        this.$router.push('/');
                    }, 3000);
                })
                .catch((e) => {
                    console.error(e);
                    this.$toast(this.errorToast('Erreur lors de la mise à jour de la campagne'));
                })
                .finally(() => {
                    this.$root.toggleGlobalLoading(false);
                });
        },
        updateTextarea(value, campaignDetail) {
            campaignDetail.comment = value;
        },
    },
};
</script>

<style>
.danger {
    color: red;
}
</style>
